.wrap {
  display: flex;
  flex-direction: column;
}

.form {
  h3 {
    margin-bottom: 20px;
  }
}

button.button,
a.button {
  margin-top: 20px;
  padding: 12px;
}

.forgot {
  font-size: 14px;
}
